export default {
  init() {
    $('.navbar-toggler').on('click', function() {
      $(this).toggleClass('active');
    });

    window.onscroll = function() {scrollFunction()};

    function scrollFunction() {
      if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 200) {
        $('header').addClass('moveup')
      } else {
        $('header').removeClass('moveup');
      }
    }

    $('.track').each(function() {
      var setid = $(this).attr('id');
      var contain = '#waveform-' + setid;
      var audio = $('#audio-' + setid).html();

      // eslint-disable-next-line
      var wavesurfer = WaveSurfer.create({
        container: contain,
        waveColor: '#999998',
        backend: 'MediaElement',
        barWidth: 2,
        barHeight: 1,
        barRadius: 3,
        barGap: null,
        height: 35,
        progressColor: '#D65645',
        responsive: true,
      });

      wavesurfer.load(audio);

      $('#play-' + setid).on('click', function() {
        wavesurfer.play();
        $(this).addClass('hide');
        $('#pause-' +setid).removeClass('hide');
      });

      $('#pause-' + setid).on('click', function() {
        wavesurfer.pause();
        $(this).addClass('hide');
        $('#play-' +setid).removeClass('hide');
      });

      wavesurfer.on('ready', function() {
        $('#total-' + setid).text(secondsToTimestamp(wavesurfer.getDuration()))
      });

      wavesurfer.on('ready', updateTimer)
      wavesurfer.on('audioprocess', updateTimer)
      wavesurfer.on('seek', updateTimer)

      function updateTimer() {
        var formattedTime = secondsToTimestamp(wavesurfer.getCurrentTime());
        $('#current-' + setid).text(formattedTime);
      }

      function secondsToTimestamp(seconds) {
        seconds = Math.floor(seconds);
        var h = Math.floor(seconds / 3600);
        var m = Math.floor((seconds - (h * 3600)) / 60);
        var s = seconds - (h * 3600) - (m * 60);

        h = h < 10 ? '0' + h : h;
        m = m < 10 ? '0' + m : m;
        s = s < 10 ? '0' + s : s;
        return m + ':' + s;
      }
    });
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
